import { useCallback, useEffect, useState } from "react";
import useActivityTypes from "../../../../../hooks/useActivityTypes";
import ActivityCard from "./ActivityCard";
import { ShowAllModal } from "../../Sections/subcomponents";
import { getSportTypeFromName } from "../../../../../utils/helpers/formatSportType";

const ITEMS_PER_PAGE = 12;

const AllActivities = ({ isShowAll, setIsShowAll, selectedCards, handleCardClick }) => {
    const { activityTypes, getActivityTypes, totalCount } = useActivityTypes();
    const [limit, setLimit] = useState(ITEMS_PER_PAGE);
    const [currentItemsCount, setCurrentItemsCount] = useState(0);

    useEffect(() => {
        const fetchInitialActivities = async () => {
            await getActivityTypes(0, limit);
            setCurrentItemsCount(activityTypes.length);
        };
        fetchInitialActivities();
    }, [limit, getActivityTypes, activityTypes.length]);

    const fetch = useCallback(async (type) => {
        const newLimit = type === 'more' ? limit + ITEMS_PER_PAGE : Math.max(ITEMS_PER_PAGE, limit - ITEMS_PER_PAGE);
        await getActivityTypes(0, newLimit);
        setLimit(newLimit);
        setCurrentItemsCount(activityTypes.length);
    }, [limit, getActivityTypes, activityTypes.length]);

    return (
        <ShowAllModal
            isShowAll={isShowAll}
            setIsShowAll={setIsShowAll}
            gap={4}
            totalCount={totalCount}
            fetch={fetch}
            currentItemsCount={currentItemsCount}
        >
            {activityTypes.slice(0, limit).map((activity, index) => {
                const isSelected = selectedCards.includes(getSportTypeFromName(activity.name))
                return (
                    <ActivityCard
                        key={`${activity.name}-${index}`}
                        activity={activity}
                        isSelected={isSelected}
                        handleCardClick={handleCardClick}
                        widthBox={200}
                    />
                )
            })}
        </ShowAllModal>
    );
};

export default AllActivities;