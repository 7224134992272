import React, { useEffect, useContext } from 'react'
import useClubs from '../../../../hooks/useClubs'
import useTrainers from '../../../../hooks/useTrainers'
import { CircularProgress, Box } from '@mui/material'
import { GoogleMapsContext } from '../../../../context/google-context'
import CustomMap from './subcomponents/CustomMap'

const MapSection = () => {
  const { clubsForMap, getClubsForMap } = useClubs()
  const { trainersForMap, getTrainersForMap } = useTrainers()
  const { isLoaded, loadError } = useContext(GoogleMapsContext)

  useEffect(() => {
    getClubsForMap()
    getTrainersForMap()
  }, [getClubsForMap, getTrainersForMap])

  if (loadError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  if (
    !isLoaded ||
    !clubsForMap ||
    !trainersForMap
  ) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box width={'70%'}>
      <CustomMap clubs={clubsForMap} trainers={trainersForMap} />
    </Box>
  )
}

export default MapSection
