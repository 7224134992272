import { useCallback, useEffect, useState } from "react";
import useClasses from "../../../../../../../../hooks/useClasses";
import ShowAllModal from "../../../ShowAllModal";
import Card from "../Card";

const AllCards = ({ showAll, setShowAll }) => {
    const { totalCount, classes, getClasses } = useClasses();
    const [limit, setLimit] = useState(12);
    const [currentItemsCount, setCurrentItemsCount] = useState(0);

    useEffect(() => {
        const fetchInitialClasses = async () => {
            await getClasses(0, limit);
            setCurrentItemsCount(classes.length);
        };

        fetchInitialClasses();
    }, [limit, getClasses, classes.length]);

    const fetch = useCallback(async (type) => {
        const newLimit = type === 'more' ? limit + 12 : limit - 12;
        await getClasses(0, newLimit);
        setLimit(newLimit);
        setCurrentItemsCount(classes.length);
    }, [limit, getClasses, classes.length]);

    return (
        <ShowAllModal
            isShowAll={showAll}
            setIsShowAll={setShowAll}
            gap={4}
            totalCount={totalCount}
            fetch={fetch}
            currentItemsCount={currentItemsCount}
        >
            {classes.map((item) => (
                <Card key={item.id} item={item} />
            ))}
        </ShowAllModal>
    );
};

export default AllCards;
