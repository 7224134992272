import React, { createContext } from 'react'
import { useJsApiLoader } from '@react-google-maps/api'

export const GoogleMapsContext = createContext()

export const GoogleMapsProvider = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-maps-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places', 'geocoding'],
    language: 'ro',
    region: 'RO'
  })

  return (
    <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleMapsContext.Provider>
  )
}
