import { useEffect, useMemo, useState, useCallback } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import useCourses from '../../../../../../hooks/useCourses'
import CustomSlider from '../../../../../common/CustomSlider'
import CourseCard from './subcomponent/Course'
import AllCourses from './subcomponent/AllCourses'
import { useExplore } from '../../../../../../context/explore-context'

const CourseSlider = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const itemsPerRow = isMobile ? 1 : 4
  const [showAll, setShowAll] = useState(false)
  const { totalCount, courses, getCourses } = useCourses()
  const { searchQuery } = useExplore()

  const fetchCourses = useCallback(
    async (targetIndex) => {
      const newLimit = (targetIndex + 1) * itemsPerRow + itemsPerRow
      await getCourses(0, newLimit, searchQuery)
    },
    [getCourses, itemsPerRow, searchQuery]
  )

  useEffect(() => {
    if (showAll) return
    fetchCourses(0)
  }, [fetchCourses, showAll])

  const renderedCourseCards = useMemo(() => {
    return courses.map((course) => (
      <Box key={course.id} marginRight={!isMobile && 1} padding={!isMobile && '0 0 10px 10px'}>
        <CourseCard course={course} />
      </Box>
    ))
  }, [courses])

  return (
    <>
      {showAll && courses.length > 0 ? (
        <AllCourses isShowAll={showAll} setIsShowAll={setShowAll} courses={courses} />
      ) : (
        <Box sx={{ padding: '20px 0', position: 'relative' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="h5" fontWeight="bold" sx={{ ml: 1 }}>
              Courses
            </Typography>
            {courses.length > 0 && (
              <Typography
                variant="h5"
                color="primary"
                sx={{ cursor: 'pointer', mr: 1 }}
                onClick={() => setShowAll((prev) => !prev)}>
                Show All
              </Typography>
            )}
          </Box>

          {courses.length > 0 ? (
            <CustomSlider totalCount={totalCount} itemsPerRow={itemsPerRow} fetch={fetchCourses}>
              {renderedCourseCards}
            </CustomSlider>
          ) : (
            <Typography variant="h6" color="text.secondary">
              No courses found
            </Typography>
          )}
        </Box>
      )}
    </>
  )
}

export default CourseSlider
