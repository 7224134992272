import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab
} from '@mui/material'
import { useState } from 'react'
import { DetailsDialog } from './DialogTab'
import LessonsDialog from './LessonsTab'
import PriceDialog from './PricesTab'
import AuthorDialog from './AuthorTab'

const CourseDialog = ({ course, open, handleClose }) => {

  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm"disableEnforceFocus
  disableAutoFocus >
      <DialogTitle
        display={'flex'}
        justifyContent={'space-around'}
        alignItems={'center'}
        fontWeight={'bold'}>
        {course.title}
      </DialogTitle>
      <DialogContent
        dividers
        style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
        <img
          src={course.imageUrl}
          height={'200px'}
          width={'100%'}
          style={{ borderRadius: '10px' }}
        />
      </DialogContent>
      <Box sx={{ width: '100%', boxSizing: 'border-box', overflow: 'hidden', height: '500px' }}>
        <Tabs value={tabValue} sx={{ width: '100%' }} onChange={handleTabChange} centered>
          <Tab label="Details" sx={{ width: '25%', color: tabValue === 0 ? 'blue' : 'black' }} />
          <Tab label="Lessons" sx={{ width: '25%', color: tabValue === 1 ? 'blue' : 'black' }} />
          <Tab label="Prices" sx={{ width: '25%', color: tabValue === 2 ? 'blue' : 'black' }} />
          <Tab label="Author" sx={{ width: '25%', color: tabValue === 3 ? 'blue' : 'black' }} />
        </Tabs>
        {tabValue === 0 && <DetailsDialog course={course} />}
        {tabValue === 1 && <LessonsDialog Lessons={course} />}
        {tabValue === 2 && <PriceDialog price={course.price} />}
        {tabValue === 3 && <AuthorDialog author={course} />}
      </Box>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CourseDialog
