export const PaperSyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 250,
  width: 220,
  color: 'white',
  borderRadius: '15px'
}

export const ColumnBox = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: 10
}
