import { useEffect, useState, useCallback } from 'react'
import useClubs from '../../../../../../../hooks/useClubs'
import ShowAllModal from '../../ShowAllModal'
import ClubCard from './ClubCard'
import { useExplore } from '../../../../../../../context/explore-context'

const ITEMS_PER_PAGE = 12

const AllClubs = ({ showAll, setShowAll }) => {
  const { clubs, getClubs, totalCount } = useClubs()
  const [limit, setLimit] = useState(ITEMS_PER_PAGE)
  const { selectedActivities, selectedRange, currentPosition, searchQuery } = useExplore()
  const [currentItemsCount, setCurrentItemsCount] = useState(0)

  useEffect(() => {
    const fetchInitialClubs = async () => {
      await getClubs(0, limit, selectedActivities, selectedRange, currentPosition, searchQuery)
      setCurrentItemsCount(clubs.length)
    }
    fetchInitialClubs()
  }, [limit, getClubs, selectedActivities])

  const fetch = useCallback(
    async (type) => {
      const newLimit =
        type === 'more' ? limit + ITEMS_PER_PAGE : Math.max(ITEMS_PER_PAGE, limit - ITEMS_PER_PAGE)
      await getClubs(0, newLimit, selectedActivities)
      setLimit(newLimit)
      setCurrentItemsCount(clubs.length)
    },
    [limit, getClubs, selectedActivities, clubs.length]
  )

  return (
    <ShowAllModal
      isShowAll={showAll}
      setIsShowAll={setShowAll}
      gap={8}
      totalCount={totalCount}
      fetch={fetch}
      currentItemsCount={currentItemsCount}>
      {clubs.map((club) => (
        <ClubCard key={club.id} club={club} />
      ))}
    </ShowAllModal>
  )
}

export default AllClubs
