import ChallengeCard from './ChalangesCard';
import { useEffect, useState, useCallback, useMemo } from 'react';
import useChallenges from '../../../../../../../hooks/useChallanges';
import ShowAllModal from '../../ShowAllModal';
import { Box } from '@mui/material';

const ITEMS_PER_PAGE = 12;

const AllCards = ({ isShowAll, setIsShowAll }) => {
  const { challenges, getChallenges, totalCount } = useChallenges();
  const [limit, setLimit] = useState(ITEMS_PER_PAGE);
  const [currentItemsCount, setCurrentItemsCount] = useState(0);

  useEffect(() => {
    const fetchInitialChallenges = async () => {
      await getChallenges(0, limit);
      setCurrentItemsCount(challenges.length);
    };
    fetchInitialChallenges();
  }, [limit, getChallenges, challenges.length]);

  const fetch = useCallback(async (type) => {
    const newLimit = type === 'more' ? limit + ITEMS_PER_PAGE : Math.max(ITEMS_PER_PAGE, limit - ITEMS_PER_PAGE);
    await getChallenges(0, newLimit);
    setLimit(newLimit);
    setCurrentItemsCount(challenges.length);
  }, [limit, getChallenges, challenges.length]);

  const renderCards = useMemo(() => {
    return challenges.map((challenge, index) => (
      <Box key={challenge.id || index}>
        <ChallengeCard challenges={challenge} />
      </Box>
    ))
  }, [challenges]);

  return (
    <ShowAllModal
      isShowAll={isShowAll}
      setIsShowAll={setIsShowAll}
      gap={4}
      totalCount={totalCount}
      fetch={fetch}
      currentItemsCount={currentItemsCount}
    >
      {renderCards}
    </ShowAllModal>
  );
};

export default AllCards;
