import React, { useState, useEffect } from 'react'
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Tabs,
  Tab,
  Button
} from '@mui/material'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import {
  BoxFlexBetween,
  DialogTitleStyle,
  ImageStyle,
  OverlayBoxStyle,
  StatusTypography,
  AvatarStyle,
  ContentBoxStyle,
  IconBoxStyle,
  InviteOnlyBoxStyle,
  HourglassBoxStyle,
  TimeTextStyle,
  ErrorOutStyle,
  TextChallenge,
  BoxCFlex,
  IconStyle,
  IconText,
  IconTextWhite,
  IconTextGray,
  IconColor
} from './index.styled'
import Leaderboard from './Leaderboard'
import RewardChallenge from './Rewards'

const DialogChallenge = ({ challenges, open, handleClickClose }) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  const [tabValue, setTabValue] = useState(0)

  const timeoutRef = React.useRef(null)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const getTimeRemaining = (startDate, startHour) => {
    const dateParts = startDate.split('-').map(Number)
    if (dateParts.length !== 3) {
      console.error('Invalid startDate format')
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }
    const [day, month, year] = dateParts

    const timeParts = startHour.split(':').map(Number)
    if (timeParts.length !== 2) {
      console.error('Invalid startHour format')
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }
    const [hour, minute] = timeParts

    const eventDateTime = new Date(year, month - 1, day, hour, minute, 0, 0)

    if (isNaN(eventDateTime)) {
      console.error('Invalid eventDateTime')
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }

    const now = new Date()
    const difference = eventDateTime - now

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }

    const totalSeconds = Math.floor(difference / 1000)
    const days = Math.floor(totalSeconds / 86400)
    const hours = Math.floor((totalSeconds % 86400) / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60

    return { days, hours, minutes, seconds }
  }

  useEffect(() => {
    if (open) {
      setTimeRemaining(getTimeRemaining(challenges.startDate, challenges.startHour))

      const interval = setInterval(() => {
        const updatedTime = getTimeRemaining(challenges.startDate, challenges.startHour)
        setTimeRemaining(updatedTime)

        if (
          updatedTime.days === 0 &&
          updatedTime.hours === 0 &&
          updatedTime.minutes === 0 &&
          updatedTime.seconds === 0
        ) {
          clearInterval(interval)
        }
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [open, challenges.startDate, challenges.startHour])

  const getStoreUrl = () => {
    const userAgent = navigator.userAgent.toLowerCase()

    if (userAgent.includes('android')) {
      return 'https://play.google.com/store/apps/details?id=com.c11.sportin.challenges&hl=ro'
    }
    if (/iphone|ipad|ipod/.test(userAgent)) {
      return 'https://apps.apple.com/ro/app/challenges-by-sportin/id6503179947?l=ro'
    }

    return 'https://play.google.com/store/apps/details?id=com.c11.sportin.challenges&hl=ro'
  }

  const isChallengeOngoing = (startDate, endDate) => {
    const now = new Date()

    const startParts = startDate.split('-').map(Number)
    const endParts = endDate.split('-').map(Number)

    const startDateObj = new Date(startParts[2], startParts[1] - 1, startParts[0])
    const endDateObj = new Date(endParts[2], endParts[1] - 1, endParts[0])

    return now >= startDateObj && now < endDateObj
  }

  const openAppOrStore = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    let appUrl = ''

    if (userAgent.includes('android')) {
      appUrl = 'com.c11.sportin.challenges://'
    } else if (/iphone|ipad|ipod/.test(userAgent)) {
      appUrl = 'challengesbysportin://'
    } else {
      window.open(getStoreUrl(), '_blank')
      return
    }

    const storeUrl = getStoreUrl()

    timeoutRef.current = setTimeout(() => {
      window.location.href = storeUrl
    }, 1000)

    window.location.href = appUrl

    const handleBlur = () => {
      clearTimeout(timeoutRef.current)
      window.removeEventListener('blur', handleBlur)
    }

    window.addEventListener('blur', handleBlur)
  }

  return (
    <Dialog open={open} onClose={handleClickClose} fullWidth maxWidth="sm">
      <DialogTitle sx={DialogTitleStyle}>
        <Box sx={{ position: 'relative', width: '100%', height: '200px' }}>
          <img src={challenges.imageUrl} alt="Title Image" style={ImageStyle} />
          <Box sx={OverlayBoxStyle}>
            <Box sx={{ marginTop: 'auto', textAlign: 'left' }}>
              {challenges.status && (
                <Typography sx={StatusTypography(challenges.status)}>
                  {challenges.status}
                </Typography>
              )}
              <Box sx={{ ...BoxFlexBetween, flexDirection: 'column', width: '100%' }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    textAlign: 'left',
                    marginBottom: '20px'
                  }}>
                  {challenges.title}
                </Typography>
                {challenges.avatar && challenges.avatar !== '' && (
                  <img src={challenges.avatar} alt="Challenge Avatar" style={AvatarStyle} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogTitle>

      <Box display="flex" flexDirection="row" backgroundColor="#1e1e28">
        <DialogContent
          dividers
          sx={{
            ...ContentBoxStyle
          }}>
          <Box display="flex" justifyContent="space-around" mb={2}>
            <Box sx={IconBoxStyle}>
              <DirectionsWalkIcon sx={IconColor} />
              <Typography sx={IconTextWhite}>{challenges.steps} Steps</Typography>
            </Box>

            <Box sx={IconBoxStyle}>
              <CalendarTodayIcon sx={IconColor} />
              <Typography sx={IconTextWhite}>{challenges.startDate}</Typography>
              <Typography sx={IconTextGray}>{challenges.endDate}</Typography>
            </Box>

            <Box sx={IconBoxStyle}>
              <AccessTimeIcon sx={IconColor} />
              <Typography sx={IconTextWhite}>{challenges.startHour}</Typography>
              <Typography sx={IconTextGray}>{challenges.endHour}</Typography>
            </Box>

            <Box sx={IconBoxStyle}>
              <PeopleAltIcon sx={IconColor} />
              <Typography sx={IconTextWhite}>{challenges.participants}</Typography>
            </Box>
          </Box>

          <Divider sx={{ backgroundColor: 'gray' }} />

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mt={2}
            minHeight={'200px'}>
            {challenges.isInviteOnly && (
              <Box sx={InviteOnlyBoxStyle}>
                <ErrorOutlineIcon style={ErrorOutStyle} />
                <Typography
                  variant="h5"
                  sx={{ color: '#cccc', fontWeight: 'bold', textAlign: 'center' }}>
                  This challenge can only be joined by invitation
                </Typography>
              </Box>
            )}

            {timeRemaining.seconds > 0 ? (
              <Box sx={HourglassBoxStyle}>
                <HourglassTopIcon style={IconStyle} />
                <Typography variant="h6" sx={TextChallenge}>
                  CHALLENGE WILL START IN:
                </Typography>
                <Typography variant="h5" sx={TimeTextStyle}>
                  {timeRemaining.days > 0 && `${timeRemaining.days} DAYS `}
                  {`${timeRemaining.hours} ore ${timeRemaining.minutes} minute ${timeRemaining.seconds} secunde`}
                </Typography>
              </Box>
            ) : (
              ''
            )}
            {isChallengeOngoing(challenges.startDate, challenges.endDate) && (
              <Button
                variant="contained"
                color="primary"
                onClick={openAppOrStore}
                sx={{ marginTop: '20px', width: '80%' }}>
                Join Challenge
              </Button>
            )}
          </Box>
          <Divider sx={{ backgroundColor: 'gray', mt: 2, mb: 2 }} />

          <Box sx={{ width: '100%', boxSizing: 'border-box' }}>
            <Tabs value={tabValue} sx={{ width: '100%' }} onChange={handleTabChange} centered>
              <Tab
                label="Leaderboard"
                sx={{ width: '50%', color: tabValue === 0 ? '#0067CF' : 'white' }}
              />
              <Tab
                label="Rewards & Info"
                sx={{ width: '50%', color: tabValue === 1 ? '#0067CF' : 'white' }}
              />
            </Tabs>
            {tabValue === 0 && <Leaderboard challenges={challenges} />}
            {tabValue === 1 && <RewardChallenge challenge={challenges} />}
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  )
}

export default DialogChallenge
