import { useState, useCallback } from 'react'
import { getTrainersForMapReq, getTrainersReq } from '../api/explore'

const useTrainers = () => {
  const [trainers, setTrainer] = useState([]);
  const [trainersForMap, setTrainersForMap] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const getTrainers = useCallback(async (from = 0, limit = 5, selectedActivities, selectedRange, currentPosition, searchQuery) => {
    setLoading(true);
    try {
      const res = await getTrainersReq(from, limit, selectedActivities, selectedRange, currentPosition, searchQuery);
      
      const resCount = await res.count;
      const resTrainers = await res.trainers;

      setTrainer(resTrainers);
      setTotalCount(resCount);
    } catch (error) {
      console.error('Error fetching trainers:', error);
    } finally {
      setLoading(false);
    };
  }, []);

  const getTrainersForMap = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getTrainersForMapReq();
      const resTrainers = await res.trainers;

      setTrainersForMap(resTrainers);
    } catch (error) {
      console.error('Error fetching trainers:', error);
    } finally {
      setLoading(false);
    };
  }, []);

  return { loading, totalCount, trainersForMap, trainers, getTrainers, getTrainersForMap }
}

export default useTrainers
