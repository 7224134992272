import { Avatar, Box, Card, Grid, Typography, Button } from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import {
  BorderBox,
  CardStyle,
  ImageBox,
  OverlayBox,
  priceStyles,
  subtextStyles,
  titleStyles
} from './index.styled'
import { useState } from 'react'
import CourseDialog from './Dialog/CoursesDialog'

const CourseCard = ({ course }) => {
  const getInitials = (name) => {
    if (!name) return ''
    const names = name.trim().split(' ')
    const initials = names.map((n) => n[0].toUpperCase()).join('')
    return initials.slice(0, 2)
  }

  const [open, setOpen] = useState(false)

  const handleOpenCourse = () => {
    setOpen(true)
  }

  const handleCloseCourse = () => {
    setOpen(false)
  }

  return (
    <>
      <Card style={CardStyle} onClick={handleOpenCourse}>
        <div style={BorderBox}>
          <img src={course.imageUrl} alt={course.title} style={ImageBox} />
          <div style={OverlayBox} />
        </div>
        <Box
          padding="16px"
          flexGrow={1}
          display="flex"
          flexDirection="column"
          justifyContent="space-between">
          <Grid container alignItems="center" justifyContent="space-between" marginBottom="8px">
            <Typography variant="h6" style={titleStyles}>
              {course.title}
            </Typography>
            <Typography variant="h6 " color="primary" style={priceStyles}>
              {course.price} LEI
            </Typography>
          </Grid>

          <Grid container alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" style={subtextStyles.left}>
              <Avatar
                src={course.avatar || undefined}
                alt={course.companyName.name}
                style={{
                  width: '24px',
                  height: '24px',
                  marginRight: '8px',
                  backgroundColor: course.avatar ? 'transparent' : '#1976d2',
                  fontSize: '12px'
                }}>
                {!course.avatar && getInitials(course.companyName.name)}
              </Avatar>
              <Typography variant="body2">{course.companyName.name}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <DescriptionIcon
                style={{ color: 'blue', width: '14px', height: '14px', marginRight: '4px' }}
              />
              <Typography variant="body2" style={subtextStyles.right}>
                {course.lessons.length} Lessons
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Card>
      <CourseDialog course={course} handleClose={handleCloseCourse} open={open} />
    </>
  )
}

export default CourseCard
