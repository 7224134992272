import { Box, Button, Grid, useMediaQuery, useTheme } from '@mui/material'
import { ReactComponent as Logo } from '../../../../../../../../assets/logo-simple.svg'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Mag_Play from '../../../../../../../../assets/googleplay.png'
import App_Store from '../../../../../../../../assets/appstore.png'

const ExploreHeader = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        width: '100%',
        marginBottom: '4%',
        ...(isMobile && {
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2
        })
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Logo height={40} width={40} />
      </Box>

      <Grid
        container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 2,
          ...(isMobile && {
            justifyContent: 'center'
          })
        }}>
        <Grid item>
          <Button
            component="a"
            href="https://play.google.com/store/apps/details?id=com.c11.sportin.challenges"
            target="_blank"
            rel="noopener noreferrer">
            <img alt="sportin-mag-play" src={Mag_Play} width="120px" />
          </Button>
        </Grid>

        <Grid item>
          <Button
            component="a"
            href="https://apps.apple.com/ro/app/challenges-by-sportin/id6503179947"
            target="_blank"
            rel="noopener noreferrer">
            <img alt="sportin-app-store" src={App_Store} width="120px" />
          </Button>
        </Grid>

        <Grid item>
          <Button
            onClick={() => navigate('/login')}
            variant="outlined"
            sx={{ backgroundColor: 'transparent' }}>
            {t('button.login')}
          </Button>
        </Grid>

        <Grid item>
          <Button onClick={() => navigate('/signup')} color="primary" variant="contained">
            {t('button.signup')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ExploreHeader
