import React, { useState, useEffect, useMemo, useContext, useRef } from 'react'
import {
  CircularProgress,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { ClubCallout, ExploreHeader, TrainerCallout } from './subcomponents'
import { useExplore } from '../../../../../../context/explore-context'
import { GoogleMapsContext } from '../../../../../../context/google-context'
import { isValidCoordinate } from '../../../../../../utils/helpers/validator'
import SearchBar from './subcomponents/Filters/SearchBar'

const CustomMap = ({ clubs, trainers }) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { isLoaded, loadError } = useContext(GoogleMapsContext)
  const { currentPosition, setCurrentPosition } = useExplore()
  const [selectedClub, setSelectedClub] = useState(null)
  const [selectedTrainer, setSelectedTrainer] = useState(null)
  const [showClubs, setShowClubs] = useState(true)
  const [showTrainers, setShowTrainers] = useState(true)
  const [geoError, setGeoError] = useState(null)
  const mapRef = useRef(null)
  const [userLocation, setUserLocation] = useState(null)

  useEffect(() => {
    if (!navigator.geolocation) {
      setGeoError('Geolocation is not supported by your browser.')
      return
    }

    const geoSuccess = (position) => {
      const { latitude, longitude } = position.coords
      if (isValidCoordinate(latitude) && isValidCoordinate(longitude)) {
        setCurrentPosition({ lat: latitude, lng: longitude })
      } else {
        console.error('Invalid geolocation coordinates:', position.coords)
        setGeoError('Received invalid location coordinates.')
      }
    }

    const geoErrorHandler = (error) => {
      console.error('Error obtaining location:', error)
      setGeoError('Unable to retrieve your location.')
    }

    navigator.geolocation.getCurrentPosition(geoSuccess, geoErrorHandler)
  }, [setCurrentPosition])

  const mapContainerStyle = useMemo(
    () => ({
      position: 'relative',
      marginTop: '10px',
      width: isSmallScreen ? '100%' : '90%',
      height: isSmallScreen ? '300px' : 'calc(90vh - 200px)',
      maxHeight: '400px',
      borderRadius: '30px',
      outline: 'none',
      border: 0,
      position: 'relative',
      justifyContent: 'center'
    }),
    [isSmallScreen]
  )

  const handleLocationIconClick = (position) => {
    if (position && isValidCoordinate(position.lat) && isValidCoordinate(position.lng)) {
      setCurrentPosition({ lat: position.lat, lng: position.lng })
      setUserLocation({ lat: position.lat, lng: position.lng })

      if (mapRef.current) {
        mapRef.current.panTo({ lat: position.lat, lng: position.lng })
        mapRef.current.setZoom(14)
      }
    }
  }

  const mapOptions = useMemo(
    () => ({
      mapTypeId: 'hybrid',
      disableDefaultUI: true,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      attributionControl: false,
      zoomControl: false,
      clickableIcons: false,
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'transit',
          stylers: [{ visibility: 'off' }]
        }
      ]
    }),
    []
  )

  const clubMarkerIcon = useMemo(() => {
    if (!isLoaded || typeof window === 'undefined' || !window.google || !window.google.maps)
      return null
    return {
      url: 'https://i.imgur.com/UIJq4Xg_d.webp?maxwidth=760&fidelity=grand',
      scaledSize: new window.google.maps.Size(40, 40)
    }
  }, [isLoaded])

  const trainerMarkerIcon = useMemo(() => {
    if (!isLoaded || typeof window === 'undefined' || !window.google || !window.google.maps)
      return null
    return {
      url: 'https://i.imgur.com/N4LWwvK.png',
      scaledSize: new window.google.maps.Size(40, 40)
    }
  }, [isLoaded])

  if (loadError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="error">
          Error loading Google Maps
        </Typography>
      </Box>
    )
  }

  const hasValidPosition =
    currentPosition &&
    isValidCoordinate(currentPosition.lat) &&
    isValidCoordinate(currentPosition.lng)

  if (!isLoaded || !hasValidPosition) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  if (geoError) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100%">
        <Typography variant="h6" color="error" gutterBottom>
          {geoError}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords
                if (isValidCoordinate(latitude) && isValidCoordinate(longitude)) {
                  setCurrentPosition({ lat: latitude, lng: longitude })
                  setGeoError(null)
                } else {
                  console.error('Invalid geolocation coordinates on retry:', position.coords)
                  setGeoError('Received invalid location coordinates.')
                }
              },
              (error) => {
                console.error('Error obtaining location on retry:', error)
                setGeoError('Unable to retrieve your location.')
              }
            )
          }}>
          Retry
        </Button>
      </Box>
    )
  }

  return (
    <Box
      width={'100%'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative'
      }}>
      <ExploreHeader />

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={currentPosition}
        zoom={14}
        options={mapOptions}>
        {showClubs &&
          clubMarkerIcon &&
          clubs?.map((club) => {
            if (!isValidCoordinate(club.locationLat) || !isValidCoordinate(club.locationLng)) {
              console.warn(`Invalid club coordinates for club ID ${club.id}:`, {
                lat: club.locationLat,
                lng: club.locationLng
              })
              return null
            }

            return (
              <Marker
                key={club.id}
                position={{ lat: club.locationLat, lng: club.locationLng }}
                icon={clubMarkerIcon}
                onClick={() => setSelectedClub(club)}
              />
            )
          })}
        {showClubs &&
          clubMarkerIcon &&
          clubs?.map((club) => (
            <Marker
              key={club.id}
              position={{ lat: club.locationLat, lng: club.locationLng }}
              icon={clubMarkerIcon}
              onClick={() => setSelectedClub(club)}
            />
          ))}

        {showTrainers &&
          trainerMarkerIcon &&
          trainers?.map((trainer) => (
            <Marker
              key={trainer.id}
              position={{ lat: trainer.locationLat, lng: trainer.locationLng }}
              icon={trainerMarkerIcon}
              onClick={() => setSelectedTrainer(trainer)}
            />
          ))}

        {selectedClub && (
          <ClubCallout selectedClub={selectedClub} setSelectedClub={setSelectedClub} />
        )}

        {selectedTrainer && (
          <TrainerCallout
            selectedTrainer={selectedTrainer}
            setSelectedTrainer={setSelectedTrainer}
          />
        )}

        {userLocation && (
          <Marker
            position={userLocation}
            icon={{
              url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
              scaledSize: new window.google.maps.Size(40, 40)
            }}
            title="You are here"
          />
        )}
        <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '15px',
            zIndex: 100,
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '5px 10px',
            ...(isSmallScreen && {
              width: '65%',
              height: '25%'
            })
          }}>
          <FormControlLabel
            control={<Checkbox checked={showClubs} onChange={() => setShowClubs(!showClubs)} />}
            label="Show Clubs"
          />
          <FormControlLabel
            control={
              <Checkbox checked={showTrainers} onChange={() => setShowTrainers(!showTrainers)} />
            }
            label="Show Trainers"
          />
        </div>
      </GoogleMap>
      <SearchBar
        position={currentPosition}
        onSearch={() => {}}
        onLocationIconClick={handleLocationIconClick}
      />
    </Box>
  )
}

export default CustomMap
