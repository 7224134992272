export const BoxStyle = {
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: 10,
  paddingRight: 10,
  paddingBottom: 10
}

export const ColumnFlex = {
  display: 'flex',
  width: '80%',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  flexGrow: 0
}

export const CenterBox = {
  paddingLeft: 0.5,
  display: 'flex',
  alignItems: 'center'
}

export const RowBox = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center'
}

export const BoxFlexEnd = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '20%',
  marginTop: 'auto'
}

export const RadiusBox = {
  borderRadius: 6,
  border: `2px solid white`,
  height: 60,
  width: 60
}

export const PaperBox = {
  backgroundSize: 'cover',
  boxShadow: `1px 2px 2px rgba(0, 0, 0, 0.3)`,
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 220,
  color: 'white',
  borderRadius: 2,
  '&:hover': {
    cursor: 'pointer'
  }
}
