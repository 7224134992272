import { Avatar, Box, Link, TableCell, Typography } from "@mui/material";

const UserDetailsCell = ({ userDetails }) => (
    <TableCell sx={{ display: 'flex', alignItems: 'center', gap: '6%', width: '215px', border: 'none !important' }}>
        <Avatar src={userDetails.avatar} />
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Link fontSize={'1rem'}>{userDetails.name}</Link>
            <Typography fontSize={'1rem'}>+{userDetails.phone}</Typography>
        </Box>
    </TableCell>
);

export default UserDetailsCell;