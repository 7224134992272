export const getSportTypeFromName = (sportName) => {
    switch (sportName) {
        case "Animal":
            return "ANIMAL";
        case "Aerobic":
            return "AEROBIC";
        case "Aerobic Step":
            return "AEROBIC_STEP";
        case "Aerial Fitness":
            return "AERIAL_FITNESS";
        case "Active Recovery":
            return "ACTIVE_RECOVERY";
        case "Balet":
            return "BALET";
        case "Bodybuilding":
            return "BODYBUILDING";
        case "Boxing":
            return "BOXING";
        case "Bootcamp":
            return "BOOTCAMP";
        case "Circuit Training":
            return "CIRCUIT_TRAINING";
        case "Calisthenics":
            return "CALISTHENICS";
        case "Cycling":
            return "CYCLING";
        case "Crossfit":
            return "CROSSFIT";
        case "Dance":
            return "DANCE";
        case "EMS Training":
            return "EMS_TRAINING";
        case "Football":
            return "FOOTBALL";
        case "Gymnastics":
            return "GYMNASTICS";
        case "Hypoxi Vacuum Fitness":
            return "HYPOXI_VACUUM_FITNESS";
        case "Horse Riding":
            return "HORSE_RIDING";
        case "Home Cycling":
            return "HOME_CYCLING";
        case "Hiking":
            return "HIKING";
        case "Interval Training":
            return "INTERVAL_TRAINING";
        case "Kangoo Jump":
            return "KANGOO_JUMP";
        case "Indoor Cycling":
            return "INDOOR_CYCLING";
        case "KickBoxing":
            return "KICKBOXING";
        case "Kinesiotherapy":
            return "KINESIOTHERAPY";
        case "Meditation":
            return "MEDITATION";
        case "Multilevel Group Training":
            return "MULTILEVEL_GROUP_TRAINING";
        case "Martial Arts":
            return "MARTIAL_ARTS";
        case "Nutrition":
            return "NUTRITION";
        case "Personal Training":
            return "PERSONAL_TRAINING";
        case "Pilates":
            return "PILATES";
        case "Pole Fitness":
            return "POLE_FITNESS";
        case "Physiotherapy":
            return "PHYSIOTHERAPY";
        case "Pregnant Training":
            return "PREGNANT_TRAINING";
        case "Postural Alignment":
            return "POSTURAL_ALIGNMENT";
        case "Running":
            return "RUNNING";
        case "Swimming":
            return "SWIMMING";
        case "Strength Condition":
            return "STRENGTH_CONDITION";
        case "Spartan Race":
            return "SPARTAN_RACE";
        case "Suspension Training":
            return "SUSPENSION_TRAINING";
        case "Tennis":
            return "TENNIS";
        case "Trail Running":
            return "TRAIL_RUNNING";
        case "Volleyball":
            return "VOLLEYBALL";
        case "Weight Training":
            return "WEIGHT_TRAINING";
        case "Workshop":
            return "WORKSHOP";
        case "Yoga":
            return "YOGA";
        case "Zumba":
            return "ZUMBA";
        case "Basketball":
            return "BASKETBALL";
        case "Bowling":
            return "BOWLING";
        case "Football":
            return "FOTBAL";
        case "Hiking":
            return "HIKING";
        case "Karting":
            return "KARTING";
        case "Massage":
            return "MASSAGE";
        case "Online":
            return "ONLINE";
        case "Padel":
            return "PADEL";
        case "Paintball":
            return "PAINTBALL";
        case "Squash":
            return "SQUASH";
        case "Snooker":
            return "SNOOKER";
        case "Tennis Clay":
            return "TENNIS_CLAY";
        case "Tennis Grass":
            return "TENNIS_GRASS";
        case "Tennis Table":
            return "TENNIS_TABLE";
        case "Dietician":
            return "DIETICIAN";
        case "Horse":
            return "HORSE";
        case "Indoor Climb":
            return "INDOOR_CLIMB";
        case "Physio":
            return "PHYSIO";
        case "Shooting":
            return "SHOOTING";
        case "Others":
            return "OTHERS";
        default:
            return sportName;
    }
}