export const BoxComponentStyle = {
  position: 'relative',
  top: '-20px',
  gap: 2,
  display: 'flex',
  height: '90px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 35px',
  background: 'rgba(204, 204, 204, 1)',
  borderRadius: '12px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  margin: '0 auto',
  width: '70%'
}

export const TextComponentStyle = {
  width: '40%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    paddingRight: '10px'
  }
}

export const LocationSearch = {
  width: '100%',
  marginRight: '15px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    paddingRight: '10px'
  }
}

export const ButtonSearchStyle = {
  backgroundColor: '#1a73e8',
  borderRadius: '12px',
  padding: '10px 30px',
  textTransform: 'none'
}


export const SuggestionsListStyle = {
  position: 'absolute',
  backgroundColor: '#fff',
  zIndex: 10,
  width: '100%',
  maxHeight: '200px',
  overflowY: 'auto',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px'
}

export const SuggestionItemStyle = {
  padding: '10px 16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f0f0f0'
  }
}