import { useEffect, useState, useCallback } from 'react';
import useCourses from '../../../../../../../hooks/useCourses';
import ShowAllModal from '../../ShowAllModal';
import CourseCard from './Course';

const ITEMS_PER_PAGE = 12;

const AllCourses = ({ isShowAll, setIsShowAll }) => {
  const { totalCount, courses, getCourses } = useCourses();
  const [limit, setLimit] = useState(ITEMS_PER_PAGE);
  const [currentItemsCount, setCurrentItemsCount] = useState(0);

  useEffect(() => {
    const fetchCourses = async () => {
      await getCourses(0, limit);
      setCurrentItemsCount(courses.length);
    };
    fetchCourses();
  }, [limit, getCourses, courses.length]);

  const fetch = useCallback(async (type) => {
    const newLimit = type === 'more' ? limit + ITEMS_PER_PAGE : Math.max(ITEMS_PER_PAGE, limit - ITEMS_PER_PAGE);
    await getCourses(0, newLimit);
    setLimit(newLimit);
    setCurrentItemsCount(courses.length);
  }, [limit, getCourses, courses.length]);

  return (
    <ShowAllModal
      isShowAll={isShowAll}
      setIsShowAll={setIsShowAll}
      totalCount={totalCount}
      fetch={fetch}
      currentItemsCount={currentItemsCount}
      gap={4}
    >
      {courses.map((course) => (
        <CourseCard key={course.id} course={course} />
      ))}
    </ShowAllModal>
  );
};

export default AllCourses;
