import { Chip, TableCell } from "@mui/material";

const bookedStyle = { width: 'auto', backgroundColor: '#E5EFFF', border: '0.1px solid #0664F2', fontSize: '1rem', fontWeight: 'normal', color: '#0664F2' }

const ActionCell = ({ action }) => {
    const getCorrectStyleForAction = (action) => {
        switch (action) {
            case "REVIEW":
                return { backgroundColor: '#E0FFEB', border: '0.1px solid #00A83A', color: '#00A83A' }
            case "UN_BOOK":
                return { backgroundColor: '#FFDEDE', border: '0.1px solid #F40303', color: '#F40303' }
            case "EDIT_EVENT":
                return { backgroundColor: '#EFEFEF', border: '0.1px solid #000000', color: '#000000' }
            default:
                return {};
        }
    };

    return (
        <TableCell sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '148px', border: 'none !important' }}>
            <Chip label={action} sx={{ ...bookedStyle, ...(getCorrectStyleForAction(action)) }} />
        </TableCell>
    )
};

export default ActionCell;