import { useCallback, useState } from 'react'
import { getClubsForMapReq, getClubsReq, searchClubReq } from '../api/explore'

const useClubs = () => {
  const [clubs, setClubs] = useState([])
  const [clubsForMap, setClubsForMap] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)

  const getClubs = useCallback(
    async (from = 0, limit = 5, selectedActivities, selectedRange, currentPosition, searchQuery) => {
      setLoading(true)
      try {
        const res = await getClubsReq(
          from,
          limit,
          selectedActivities,
          selectedRange,
          currentPosition,
          searchQuery
        )

        const resCount = await res.count
        const resClubs = await res.clubs

        setClubs(resClubs)
        setTotalCount(resCount)
      } catch (error) {
        console.error('Error fetching clubs', error)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const getClubsForMap = useCallback(async () => {
    setLoading(true)
    try {
      const res = await getClubsForMapReq()
      const resClubs = await res.clubs

      setClubsForMap(resClubs)
    } catch (error) {
      console.error('Error fetching clubs', error)
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    clubs,
    clubsForMap,
    loading,
    getClubs,
    getClubsForMap,
    totalCount
  }
}

export default useClubs
