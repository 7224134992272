import React from 'react'
import { Card, Typography, Box } from '@mui/material'
import {
  BoxFlexBetween,
  BoxStyle,
  BoxStyle2,
  CardComponent,
  DividerComponent,
  FlexColumn,
  TextStyle,
  BoxIcon,
  IconSize
} from './index.styled'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

const ChallengeCard = ({ challenges, onOpen }) => {
  return (
    <>
      <Card sx={CardComponent(challenges.imageUrl)} onClick={onOpen}>
        <Box sx={BoxFlexBetween}>
          <Box sx={FlexColumn}>
            {challenges.avatar && challenges.avatar !== '' && (
              <img
                src={challenges.avatar}
                alt="Challenge Avatar"
                width="36px"
                height="36px"
                style={{ margin: 0, padding: 0, borderRadius: '5px' }}
              />
            )}
          </Box>
          <Box sx={BoxStyle}>
            <Typography
              fontSize={'12px'}
              fontWeight={'bold'}
              fontStyle={'italic'}
              style={{
                backgroundColor: 'rgba(0,0,0,0.4)',
                borderRadius: '5px',
                padding: '5px'
              }}>
              {challenges.rewardAmount > 0 ? `${challenges.rewardAmount} EUR` : 'REWARD'}
            </Typography>
            <Typography
              fontSize={'12px'}
              sx={{
                ...TextStyle,
                borderRadius: '5px',
                backgroundColor:
                  challenges.status === 'PENDING'
                    ? '#F79900'
                    : challenges.status === 'ACTIVE'
                    ? '#057AF6'
                    : challenges.status === 'ONGOING'
                    ? '#01703C'
                    : '#007bff'
              }}>
              {challenges.status}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ padding: '2px 10px' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {challenges.title}
          </Typography>
          <Box sx={BoxStyle2}>
            <Typography variant="body2" style={BoxIcon}>
              <img
                src={
                  'https://cdn4.iconfinder.com/data/icons/simple-goods-services-outline/30/running-512.png'
                }
                width={'20px'}
                height={'20px'}
                style={{ filter: 'invert(100%)' }}
              />
              {challenges.steps} Steps
            </Typography>
            <DividerComponent />
            <Typography variant="body2" style={BoxIcon}>
              <CalendarTodayIcon style={IconSize} />
              {challenges.startDate}
            </Typography>
            <DividerComponent />
            <Typography variant="body2" style={BoxIcon}>
              <PeopleAltIcon color="#cccc" style={IconSize} />
              {challenges.participants} JOINED
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  )
}

export default ChallengeCard
