export const cardStyle = (imgUrl) => ({
  position: 'relative',
  width: '350px',
  height: '220px',
  borderRadius: 2,
  backgroundImage: `linear-gradient(to bottom, transparent, rgba(0,0,0,0.5)), url(${imgUrl})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  cursor: 'pointer',
  boxShadow: `1px 2px 2px rgba(0, 0, 0, 0.3)`,
  userSelect: 'none'
})

export const firstBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  color: 'white',
  gap: 1
}

export const chipStyle = {
  fontWeight: 600,
  fontSize: 9,
  height: 20,
  color: '',
  backgroundColor: 'rgba(255,255,255,0.8)'
}

export const secondBoxStyle = {
  position: 'absolute',
  bottom: 0,
  color: 'white',
  height: '20%',
  width: '91%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingX: 2
}
