import React, { useState, useRef, useEffect } from 'react';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const CustomSlider = ({ totalCount, fetch, children, itemsPerRow }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const MAX_VISIBLE_DOTS = isMobile ? 6 : 12;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const containerRef = useRef(null);

    const slidesToShow = itemsPerRow || 3;

    // Number of pages for the slider
    const totalPages = Math.ceil(totalCount / slidesToShow);

    // Determine visible dots (pagination)
    const getVisibleDots = () => {
        if (totalPages <= MAX_VISIBLE_DOTS) return Array.from({ length: totalPages }, (_, i) => i);
        if (currentIndex < Math.floor(MAX_VISIBLE_DOTS / 2)) {
            return Array.from({ length: MAX_VISIBLE_DOTS }, (_, i) => i);
        } else if (currentIndex >= totalPages - Math.ceil(MAX_VISIBLE_DOTS / 2)) {
            return Array.from({ length: MAX_VISIBLE_DOTS }, (_, i) => totalPages - MAX_VISIBLE_DOTS + i);
        }
        return Array.from({ length: MAX_VISIBLE_DOTS }, (_, i) => currentIndex - Math.floor(MAX_VISIBLE_DOTS / 2) + i);
    };

    const visibleDots = getVisibleDots();

    // Handle Dot Clicks
    const handleDotClick = async (index) => {
        if (index === currentIndex) return; // No action if clicking the current dot
        setCurrentIndex(index);
        await loadItemsForIndex(index);
        scrollToIndex(index);
    };

    // Scroll to Index in Slider
    const scrollToIndex = (index) => {
        const containerWidth = containerRef.current.offsetWidth;
        containerRef.current.scrollTo({
            left: index * containerWidth,
            behavior: 'smooth',
        });
    };

    // Load items based on the target index
    const loadItemsForIndex = async (index) => {
        setLoading(true);
        await fetch(index);
        setLoading(false);
    };

    // Handle Next Arrow Click
    const handleNext = async () => {
        if (currentIndex + 1 < totalPages) {
            const newIndex = currentIndex + 1;
            setCurrentIndex(newIndex);
            await loadItemsForIndex(newIndex);
            scrollToIndex(newIndex);
        }
    };

    // Handle Previous Arrow Click
    const handlePrev = async () => {
        if (currentIndex - 1 >= 0) {
            const newIndex = currentIndex - 1;
            setCurrentIndex(newIndex);
            await loadItemsForIndex(newIndex);
            scrollToIndex(newIndex);
        }
    };

    // Initially load items for the first index
    useEffect(() => {
        loadItemsForIndex(currentIndex);
    }, []);

    const getVisibleItems = () => {
        const startIndex = currentIndex * slidesToShow;
        return React.Children.toArray(children).slice(startIndex, startIndex + slidesToShow);
    };

    return (
        <Box position="relative" mt={5}>
            {/* Slider Container */}
            <Box
                ref={containerRef}
                display="flex"
                overflow="hidden"
                mb={5}
                width="100%"
                sx={{ scrollSnapType: 'x mandatory', scrollBehavior: 'smooth' }}
            >
                <Box
                    display="flex"
                    width={`${totalPages * 100}%`}
                    sx={{ transition: 'transform 0.5s ease', width: 'auto' }}
                >
                    {getVisibleItems().map((child, index) => (
                        <Box
                            key={index}
                            flex={`0 0 ${100 / slidesToShow}%`}
                            sx={{ scrollSnapAlign: 'start', boxSizing: 'border-box', width: 'auto' }}
                        >
                            {child}
                        </Box>
                    ))}
                </Box>
            </Box>

            {/* Loading Indicator */}
            {loading && (
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    zIndex={2}
                >
                    <CircularProgress />
                </Box>
            )}

            {/* Dots and Arrows Container */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                {/* Dots on the left side */}
                <Box display="flex" justifyContent="flex-start">
                    {visibleDots.map((dotIndex) => (
                        <Box
                            key={dotIndex}
                            width={10}
                            height={10}
                            mx={1}
                            borderRadius="50%"
                            bgcolor={dotIndex === currentIndex ? 'blue' : 'gray'}
                            onClick={() => handleDotClick(dotIndex)}
                            style={{ cursor: 'pointer' }}
                        />
                    ))}
                </Box>

                {/* Arrows on the right side */}
                <Box display="flex" justifyContent="flex-end" gap={2}>
                    <Box
                        as="button"
                        onClick={handlePrev}
                        disabled={currentIndex === 0}
                        style={{
                            zIndex: 1,
                            borderRadius: '50%',
                            backgroundColor: 'black',
                            color: currentIndex === 0 ? 'gray' : '#fff',
                            width: '40px',
                            height: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: currentIndex === 0 ? 'not-allowed' : 'pointer',
                            border: 'none',
                            outline: 'none',
                        }}
                    >
                        <ArrowBackIcon style={{ color: currentIndex === 0 ? 'gray' : '#fff' }} />
                    </Box>
                    <Box
                        as="button"
                        disabled={currentIndex === totalPages - 1}
                        onClick={handleNext}
                        style={{
                            zIndex: 1,
                            borderRadius: '50%',
                            backgroundColor: 'black',
                            color: currentIndex === totalPages - 1 ? 'gray' : '#fff',
                            width: '40px',
                            height: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: currentIndex === totalPages - 1 ? 'not-allowed' : 'pointer',
                            border: 'none',
                            outline: 'none',
                        }}
                    >
                        <ArrowForwardIcon style={{ color: currentIndex === totalPages - 1 ? 'gray' : '#fff' }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CustomSlider;
