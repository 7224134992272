import { Box, Card, Typography } from '@mui/material'
import { cardStyles, overlayStyles, TypoStyleLeft } from '../index.styled'
import { getBackgroundImage } from '../../../../../utils/helpers/bgImages'

const ActivityCard = ({ activity, isSelected, widthBox, handleCardClick, isMobile }) => (
  <Box
    padding={!isMobile && "0 0 10px 10px"}
    width={widthBox}
    onClick={() => handleCardClick(activity.name)}
    style={{
      cursor: 'pointer'
    }}>
    <Card
      style={{
        ...cardStyles(getBackgroundImage(activity.imageName), widthBox),
        border: isSelected ? '2px solid blue' : 'none',
        boxSizing: 'border-box',
      }}>
      <Box style={overlayStyles}>
        <Typography variant="h6" textAlign="center" color="white" style={{ zIndex: 2 }}>
          {activity.name}
        </Typography>
        <Typography variant="body1" style={TypoStyleLeft}>
          {activity.activitiesCount} Activities
        </Typography>
      </Box>
    </Card>
  </Box>
)

export default ActivityCard
