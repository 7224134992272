import { useState } from 'react'
import axios from 'axios'

const useLocationSearch = () => {
  const [locationDeterminate, setLocationDeterminate] = useState('')

  const searchLocation = async (query) => {
    if (!query.trim()) {
      setLocationDeterminate('Please enter a valid search query.')
      return
    }

    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      query
    )}&key=${apiKey}`

    try {
      const res = await axios.get(endpoint)
      const data = res.data

      if (data.status === 'OK' && data.results.length > 0) {
        const formattedAddress = data.results[0].formatted_address
        setLocationDeterminate(formattedAddress)
      } else {
        setLocationDeterminate('Location not found')
      }
    } catch (error) {
      console.error('Error fetching location:', error)
      setLocationDeterminate(`${error.message}`)
    }
  }

  return { locationDeterminate, searchLocation }
}

export default useLocationSearch
