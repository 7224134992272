import React, { useMemo } from 'react'
import { Avatar, Box, Typography } from '@mui/material'
import { BoxLeaderBoard } from './index.styled'

const Leaderboard = ({ challenges }) => {
  const sortedChallengers = useMemo(() => {
    return Object.values(challenges?.challengers || []).sort((a, b) => {
      if (b.metricValue === a.metricValue) {
        return a.challengerName.localeCompare(b.challengerName)
      }
      return b.metricValue - a.metricValue
    })
  }, [challenges?.challengers])

  const getRankColor = (rank) => {
    switch (rank) {
      case 1:
        return 'gold'
      case 2:
        return 'silver'
      case 3:
        return '#cd7f32'
      default:
        return 'white'
    }
  }

  return (
    <Box >
      {sortedChallengers.length === 0 ? (
        <Typography variant="h4" sx={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>
          There are no participants in this challenge
        </Typography>
      ) : (
        sortedChallengers.map((challenger, index) => (
          <Box
            key={challenger.id || index}
            display="flex"
            alignItems="center"
            mb={'5px'}
            sx={{
              backgroundColor: '#2c2c34',
              padding: '10px',
              borderRadius: '10px'
            }}>
            <Box sx={BoxLeaderBoard}>
              <Typography
                variant="body1"
                sx={{ color: getRankColor(index + 1), fontWeight: 'bold' }}>
                {index + 1}
              </Typography>
            </Box>
            <Avatar
              src={challenger.picture}
              alt={challenger.challengerName}
              sx={{ borderRadius: '50%', width: '40px', height: '40px' }}
            />
            <Box ml={2} flex="1">
              <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold' }}>
                {challenger.challengerName}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" sx={{ color: 'white' }}>
                {challenger.metricValue} st
              </Typography>
            </Box>
          </Box>
        ))
      )}
    </Box>
  )
}

export default Leaderboard
