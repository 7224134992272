import { Avatar, Box, Typography } from '@mui/material'
import { BoxDialog } from './index.styled'
import { subtextStyles } from '../index.styled'

const AuthorDialog = ({ author }) => {
  const getInitials = (name) => {
    if (!name) return ''
    const names = name.trim().split(' ')
    const initials = names.map((n) => n[0].toUpperCase()).join('')
    return initials.slice(0, 2)
  }

  return (
    <Box sx={BoxDialog}>
      <Box display="flex" alignItems="center" style={subtextStyles.left}>
        <Avatar
          src={author.avatar || undefined}
          alt={author.companyName.name}
          style={{
            width: '24px',
            height: '24px',
            marginRight: '8px',
            backgroundColor: author.avatar ? 'transparent' : '#1976d2',
            fontSize: '12px'
          }}>
          {!author.avatar && getInitials(author.companyName.name)}
        </Avatar>
        <Typography variant="body2">{author.companyName.name}</Typography>
      </Box>
      {author.longDescription ? (
        <Box sx={{ textOverflow: 'ellipsis' }}>
          <Typography variant="h6">{author.longDescription}</Typography>
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export default AuthorDialog
