import { Box, Typography } from '@mui/material'
import { BoxDialog, DescriptionBox, TextDialogContent } from './index.styled'
import LessonsDialog from './LessonsTab'

export const DetailsDialog = ({ course }) => {
  return (
    <Box sx={{ BoxDialog }}>
      <Box sx={DescriptionBox}>
        <Typography style={TextDialogContent} variant="h6">
          {course.longDescription}
        </Typography>
      </Box>
      <LessonsDialog Lessons={course} />
    </Box>
  )
}
