import { Avatar, Box, Button, Typography } from '@mui/material'
import { InfoWindow } from '@react-google-maps/api'
import { Link } from 'react-router-dom'

const Callout = ({ selectedClub, setSelectedClub }) => {
  const avatarSrc = selectedClub.avatarPhotoSrc
    ? selectedClub.avatarPhotoSrc
    : 'https://i.imgur.com/Ps233FN.png'

  return (
    <InfoWindow
      position={{
        lat: Number(selectedClub.locationLat),
        lng: Number(selectedClub.locationLng)
      }}
      onCloseClick={() => setSelectedClub(null)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 3,
          maxHeight: '100%',
          minHeight: 185,
          width: 130
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Avatar src={avatarSrc} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            {selectedClub.name}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            {selectedClub?.locationName}
          </Typography>
        </Box>
        <Link to={`/${selectedClub.domain}`}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#000',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#333'
              },
              transition: 'background-color 0.3s, transform 0.3s'
            }}>
            GO THERE
          </Button>
        </Link>
      </Box>
    </InfoWindow>
  )
}

export default Callout
