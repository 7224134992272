import { Box, Typography } from '@mui/material'
import { BoxDialog } from './index.styled'

const PriceDialog = ({ price }) => {
  return (
    <Box sx={BoxDialog}>
      {price === 0 ? (
        <Typography variant="h4">Great news! This course is FREE!!!</Typography>
      ) : (
        <Typography variant="h6">Price: ${price} RON</Typography>
      )}
    </Box>
  )
}

export default PriceDialog
