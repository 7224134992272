import { Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ColumnBox, PaperSyle } from './index.styled'

const TrainerCard = ({ row, width }) => {
  const { t } = useTranslation()

  return (
    <Link
      style={{
        textDecoration: 'none',
        width: width,
        padding: 0,
      }}
      to={`${row.domain}`}>
      <Paper
        elevation={3}
        sx={{
          boxShadow: `1px 2px 2px rgba(0, 0, 0, 0.3)`,
          ...(row.avatarPhotoSrc
            ? {
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)), url(${row.avatarPhotoSrc})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              }
            : {
                backgroundColor: 'black'
              }),
          ...PaperSyle,
          width: width
        }}>
        <Box style={{ flexGrow: 1 }}></Box>
        <Box style={ColumnBox}>
          <Typography variant="h5" noWrap fontWeight="bold" color="white" maxWidth="90%">
            {row.trainerName}
          </Typography>
          <Typography variant="body2" color="white" noWrap maxWidth="90%">
            {row.defaultSportType
              ? t(`sportTypesOptions.${row.defaultSportType}`)
              : row.locationName}
          </Typography>
        </Box>
      </Paper>
    </Link>
  )
}

export default TrainerCard
