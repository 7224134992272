export const cardStyles = (imageUrl, width) => ({
  position: 'relative',
  backgroundImage: `url(${imageUrl})`,
  width: width || '220px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '250px',
  borderRadius: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  boxShadow: `1px 2px 2px rgba(0, 0, 0, 0.3)`
})

export const overlayStyles = {
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}

export const TypoStyleLeft = {
  marginTop: '5px',
  color: 'white',
  position: 'absolute',
  bottom: 10,
  left: 20,
  zIndex: 2
}
