import { useCallback, useState } from "react";
import { getClassesReq } from "../api/explore";

const useClasses = () => {
    const [classes, setClasses] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const getClasses = useCallback(async (from = 0, limit = 4, selectedActivities, searchQuery) => {
        try {
            const { totalCount: totalCountRes, classes: classesRes } = await getClassesReq(from, limit, selectedActivities, searchQuery);

            setClasses(classesRes);
            setTotalCount(totalCountRes);
        } catch (error) {
            console.error('Error fetching classes:', error)
        }
    }, []);

    return {
        totalCount,
        classes,
        getClasses
    }
};

export default useClasses;