import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  ChallengeSlider,
  Classes,
  ClubSlider,
  CourseSlider,
  ServiceSlider,
  TrainerSlider
} from './subcomponents'

const Sections = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ width: '100%', backgroundColor: '#ccc', paddingTop: 10 }}>
      <Container maxWidth="xl">
        <Classes />
        <ChallengeSlider />
        <ServiceSlider />
        <CourseSlider />
      </Container>
      <Box sx={{ backgroundColor: '#ffff', paddingTop: 20 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingX: '5px' }}>
          <Typography fontSize={isMobile ? 30 : 44} textAlign={'center'}>Looking a proffesional or a gym near you?</Typography>
        </Box>
        <Container maxWidth="xl">
          <ClubSlider />
          <TrainerSlider />
        </Container>
      </Box>
    </Box>
  )
}

export default Sections
