import { Box } from '@mui/material'

const RewardChallenge = ({ challenge }) => {
  return (
    <Box
      height={'200px'}
      justifyContent="center"
      display="flex"
      alignContent="center"
      flexWrap="wrap">
      <div
        dangerouslySetInnerHTML={{ __html: challenge.rewardDetails }}
        style={{ textAlign: 'center', color: 'white' }}
      />
    </Box>
  )
}

export default RewardChallenge
