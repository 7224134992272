import { useState, useCallback } from 'react';
import { getServicesReq } from '../api/explore';

const useServices = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const getServices = useCallback(async (from = 0, limit = 5, searchQuery) => {
    setLoading(true);
    try {
      const res = await getServicesReq(from, limit, searchQuery);

      const resCount = await res.count;
      const resServices = await res.services;

      setServices(resServices);
      setTotalCount(resCount);
    } catch (error) {
      console.error('Error fetching services:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, totalCount, services, getServices };
};

export default useServices;
