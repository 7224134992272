import React, { useEffect, useState, useRef } from 'react'
import { TextField, Box, IconButton, InputAdornment, Button, useMediaQuery } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import {
  BoxComponentStyle,
  ButtonSearchStyle,
  LocationSearch,
  TextComponentStyle
} from './index.style'
import { useTheme } from '@emotion/react'
import { useExplore } from '../../../../../../../../context/explore-context'
import { Autocomplete } from '@react-google-maps/api'
import useLocationSearch from '../../../../../../../../hooks/useLocationSearch'

const SearchBar = ({ position, onLocationIconClick }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { searchLocation } = useLocationSearch()

  const { searchQuery, setSearchQuery } = useExplore()
  const [location, setLocation] = useState('Loading location...')
  const [isUserEditingLocation, setIsUserEditingLocation] = useState(false)
  const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery)
  const autocompleteRef = useRef(null)

  useEffect(() => {
    if (position && position.lat && position.lng && !isUserEditingLocation) {
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode(
        { location: { lat: position.lat, lng: position.lng } },
        (results, status) => {
          if (status === 'OK' && results[0]) {
            setLocation(results[0].formatted_address)
          } else {
            console.error('Geocoder failed due to: ' + status)
            setLocation('Unable to retrieve location')
          }
        }
      )
    }
  }, [position, isUserEditingLocation])

  const handleLocationChange = (e) => {
    setLocation(e.target.value)
    setIsUserEditingLocation(true)
  }

  const handleLocationIconClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          if (onLocationIconClick) {
            onLocationIconClick({ lat: latitude, lng: longitude })
          }

          const geocoder = new window.google.maps.Geocoder()
          geocoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
            if (status === 'OK' && results[0]) {
              setLocation(results[0].formatted_address)
              setIsUserEditingLocation(true)
            } else {
              console.error('Geocoder failed due to: ' + status)
              setLocation('Unable to retrieve location')
            }
          })
        },
        (error) => {
          console.error('Error retrieving geolocation:', error)
          setLocation('Unable to retrieve your location')
        }
      )
    } else {
      setLocation('Geolocation is not supported by this browser.')
    }
  }

  const handleSearchClick = () => {
    setSearchQuery(localSearchQuery)
    searchLocation(localSearchQuery)
  }

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace()
      if (place && place.geometry && place.geometry.location) {
        const { lat, lng } = place.geometry.location
        if (onLocationIconClick) {
          onLocationIconClick({ lat: lat(), lng: lng() })
        }
        if (place.formatted_address) {
          setLocation(place.formatted_address)
        }
      } else {
        console.error('No details available for input: ' + place)
      }
    }
  }
  return (
    <Box
      sx={{
        ...BoxComponentStyle,
        ...(isMobile && {
          flexDirection: 'column',
          gap: 2,
          height: '200px',
          justifyContent: 'center',
          width: '65%'
        })
      }}>
      <TextField
        id="search-input"
        value={localSearchQuery}
        onChange={(e) => setLocalSearchQuery(e.target.value)}
        variant="outlined"
        placeholder="Search inside sections"
        InputProps={{
          style: { borderRadius: '12px', backgroundColor: '#fff' }
        }}
        sx={{
          ...TextComponentStyle,
          ...(isMobile && {
            width: '100%',
            marginRight: 0
          })
        }}
      />

      <Autocomplete
        onLoad={(autocomplete) => {
          autocompleteRef.current = autocomplete
        }}
        onPlaceChanged={onPlaceChanged}>
        <TextField
          value={location}
          onChange={handleLocationChange}
          variant="outlined"
          placeholder="Location"
          InputProps={{
            style: { width: '100%', borderRadius: '12px', backgroundColor: '#fff' },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleLocationIconClick}>
                  <LocationOnIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          sx={{
            ...LocationSearch,
            ...(isMobile && {
              width: '100%',
              marginRight: 0
            })
          }}
        />
      </Autocomplete>

      <Button
        variant="contained"
        color="primary"
        sx={ButtonSearchStyle}
        onClick={handleSearchClick}>
        Search
      </Button>
    </Box>
  )
}

export default SearchBar
