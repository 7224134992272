import { Box, Chip, Typography } from '@mui/material'
import { cardStyle, firstBoxStyle, chipStyle, secondBoxStyle } from './index.styled'
import { WatchLater, Public, Timer } from '@mui/icons-material'
import { GREEN_COLOR } from '../../../../../../../../utils/constants'
import { getBackgroundImage } from '../../../../../../../../utils/helpers/bgImages'
import moment from 'moment'
import { CalendarEventDetailsModal } from '../../../../../../../Calendar/subcomponents'
import { useMemo, useState } from 'react'

const Card = ({ item }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const renderEventDetailsModal = useMemo(() => {
    if (!modalOpen) return

    return (
      <CalendarEventDetailsModal
        key={item.id}
        loadingBookUnbook={false}
        eventId={item.id}
        userSubscriptionsToUse={[]}
        handleClose={() => setModalOpen(false)}
        showPastEvents={true}
        handleOpenSubscriptionSelector={() => {
          console.log('ok 1')
        }}
        handleBookEventWithOneSubscription={(eventToUse) => console.log({ eventToUse })}
        handleDelete={() => {
          console.log('ok 2')
        }}
        handleEdit={() => {
          console.log('ok 3')
        }}
        handleMessage={() => {
          console.log('ok 4')
        }}
        handleBuyACoffee={() => {
          console.log('ok 5')
        }}
        handleOpenPayment={() => {
          console.log('ok 6')
        }}
        handleOpenWaitingList={() => {
          console.log('ok 7')
        }}
        handleBook={() => {
          console.log('ok 8')
        }}
        handleUnbook={() => {
          console.log('ok 9')
        }}
      />
    )
  }, [modalOpen])

  return (
    <>
      {renderEventDetailsModal}
      <Box
        sx={cardStyle(getBackgroundImage(item?.sportType))}
        onClick={() => setModalOpen(true)}
      >
        <Box sx={firstBoxStyle}>
          <Public fontSize="small" />
          <Typography
            variant="body2"
            fontWeight={'bold'}
            sx={{ textShadow: '3px 3px 2px rgba(0, 0, 0, 0.63)' }}>
            {item?.name}
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {Array.isArray(item?.tags) ? (
              item?.tags.map((tag) => (
                <Chip sx={{ ...chipStyle, color: tag === 'FREE' ? GREEN_COLOR : '' }} label={tag} />
              ))
            ) : (
              <Chip
                sx={{ ...chipStyle, color: item?.tags === 'FREE' ? GREEN_COLOR : '' }}
                label={item?.tags}
              />
            )}
          </Box>
        </Box>

        <Box sx={secondBoxStyle}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <WatchLater fontSize="small" />
            <Typography variant="caption" fontWeight={600}>
              {moment(item?.start).format('HH:mm')}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Timer fontSize="small" />
            <Typography variant="caption" fontWeight={600}>
              {item?.duration}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Card
